import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SignUp from "../components/signup";
import SEO from "../components/seo";

const Template = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <section className="hero is-medium is-bold">
        <div className="hero-body">
          <div className="container has-text-centered">
            <Link to={"/"}>
              <h1 className="title">Toast Avocado</h1>
            </Link>
          </div>
          <div className="column is-4 is-offset-4 has-margin-top-2rem">
            <SignUp />
          </div>
        </div>
      </section>

      <div className="container">
        <section className="articles">
          <div className="column is-8 is-offset-2">
            <div className="card article">
              <div className="card-content">
                <div className="media">
                  <div className="media-content has-text-centered">
                    <p className="title article-title">{frontmatter.title}</p>
                    <p className="subtitle article-subtitle">
                      {frontmatter.date}
                    </p>
                  </div>
                </div>
                <div
                  className="content article-body"
                  dangerouslySetInnerHTML={{ __html: html }}
                ></div>
              </div>
            </div>
          </div>
          <div className="has-text-centered has-margin-top-2rem">
            <Link to="/">← Back</Link>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

export default Template;
